<template>
  <div>
    <div class="container titleContainer">
      <div class="row">
        <div class="col-md-5 col-sm-7 createCardTitleWrapper">
          <b-card id="createCardTitle">
            <b-button
              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
              variant="none"
              to="/admin/news"
              v-b-tooltip.hover
              title="Go Back to the Script List"
              class="d-inline text-warning"
            >
              <feather-icon icon="ArrowLeftCircleIcon" size="25" />
            </b-button>
            <h3 class="d-inline text-center font-weight-bolder">
              ADD A NEW UPDATE
            </h3>
          </b-card>
        </div>
      </div>
    </div>
    <b-card id="createCardForm">
      <div class="container">
        <form @submit.prevent="storeUpdate" class="py-4">
          <div class="row justify-content-center">
            <div class="col-md-8 mb-2">
              <div class="form-group">
                <label for="title">Title</label>
                <b-form-input
                  id="title"
                  v-model="title"
                  required
                  placeholder="Title..."
                  class="form-control"
                ></b-form-input>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-8 mb-4" ref="inputGroup">
              <template>
                <div
                  v-for="(input, index) in inputValues"
                  :key="index"
                  class="form-group"
                >
                  <label :for="'contentItem' + index"
                    >Content Item Number {{ index + 1 }}</label
                  >
                  <div class="input-group">
                    <b-form-input
                      :id="'contentItem' + index"
                      v-model="input.value"
                      required
                      :placeholder="'Content Item Number ' + (index + 1)"
                      class="form-control"
                    ></b-form-input>
                    <div class="input-group-append">
                      <button
                        @click="removeInput(index)"
                        type="button"
                        class="btn btn-outline-danger"
                      >
                        <feather-icon trash-icon icon="TrashIcon" />
                      </button>
                    </div>
                  </div>
                </div>
              </template>
              <b-button
                @click="addInput"
                variant="light"
                block
                class="btn btn-secondary"
                >Add Content</b-button
              >
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-3">
              <b-button
                type="submit"
                variant="success"
                block
                class="btn btn-primary"
                >Submit</b-button
              >
            </div>
          </div>
        </form>
      </div>
    </b-card>

    <div class="loader-overlay" v-if="isLoading">
      <b-spinner
        label="Loading..."
        style="width: 6rem; height: 6rem"
        variant="success"
      />
    </div>
  </div>
</template>

<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BLink,
  BButton,
  BSpinner,
  BModal,
  VBModal,
  BFormFile,
  BFormTextarea,
  BCardText,
  BFormTags,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import store from "@/store";
import { getAccessTokenFromCookie } from "@/auth/utils";

export default {
  components: {
    BTable,
    quillEditor,
    BFormTextarea,
    BAvatar,
    BLink,
    "b-tooltip": VBTooltip,
    BCard,
    BFormTags,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      title: "",
      content: "",
      inputValues: [{ value: "" }],
      isLoading: false,
      user_token: null,
      editorOptions: {
        placeholder: "Feel free to customize the content of this latest update",
      },
    };
  },
  computed: {},
  mounted() {
    const data = getAccessTokenFromCookie();
    const userType = data[2].role;
    if (userType !== "admin") {
      router.push({ name: "error-404" });
    }
  },
  created() {},
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    storeUpdate() {
      try {
        let content = [];
        for (let i = 0; i < this.inputValues.length; i++) {
          content.push(this.inputValues[i].value);
        }
        this.isLoading = true;
        axios
          .post("/add-new-update", {
            title: this.title,
            content: content,
          })
          .then(({ data }) => {
            if (data.status == "success") {
              this.isLoading = false;
              this.showToast("success", "SaveIcon", "Success", data.message);
              router.push({ name: "adminNews" });
            } else if (data.status == "failed") {
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                data.message
              );
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.showToast("danger", "AlertTriangleIcon", "Error", error);
          });
      } catch (error) {
        this.isLoading = false;
        console.error("Error uploading files:", error);
      }
    },

    addInput() {
      this.inputValues.push({ value: "" });
      this.$nextTick(() => {
        const inputs = this.$refs.inputGroup.querySelectorAll("input");
        const lastInput = inputs[inputs.length - 1];
        lastInput.focus();
      });
    },
    removeInput(index) {
      this.inputValues.splice(index, 1);
    },
  },
  beforeRouteEnter(to, from, next) {
    axios.post("/isAdmin").then(({ data }) => {
      if (data.status === true) {
        next(true);
      } else if (data.status === false) {
        next("/error-404");
      }
    }).catch((error)=>{
      next("/login")
    });
  },
};
</script>

<style scoped>
#createCardTitle,
#createCardForm,
.createCardTitleWrapper,
[dir] .card,
.titleContainer {
  margin: 0 !important;
}

#createCardTitle,
#createCardForm {
  border-radius: 0 !important;
}

div.app-content.content
  > div.content-wrapper
  > div
  > div
  > div.container.titleContainer
  > div
  > div {
  padding: 0;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  /* Add blur effect to the background */
}

/* Center the spinner */
.loader-overlay .spinner-grow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-editor {
  height: 50vh;
}

.custom-editor .ql-toolbar.ql-snow button {
  color: white !important;
}
</style>